import Container from "@mui/material/Container";
import * as React from "react";
import { Footer } from "./Footer";
import { CustomMdxProvider } from "./mdx/CustomMdxProvider";
import { NavBar } from "./NavBar";

interface LayoutProps {
}

const Layout: React.FC<LayoutProps> = ({ children }) => {

  return (
    <>
      {/* Menu */}
      <NavBar />
      <Container maxWidth="md" sx={{ px: "1.5rem" }}>
        <CustomMdxProvider>
          {children}
        </CustomMdxProvider>
        <Footer />
      </Container>

    </>
  )
}

export default Layout

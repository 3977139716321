import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/system/createTheme';
import { SxProps } from '@mui/system/styleFunctionSx';
import React from "react";
import { Socials } from './Socials';

interface FooterProps {
	sx?: SxProps<Theme>
}

export const Footer: React.FC<FooterProps> = ({ sx = {} }) => {
	return (
		<Box my="4rem" sx={sx} >
			<hr />
			<Box my="1rem" component="footer" display="flex" justifyContent="space-between" alignItems="center">
				<Typography variant="body1" style={{ fontSize: "1rem" }}>Walid Lezzar © {new Date().getFullYear()}</Typography>
				<Socials size="medium" />
			</Box>
		</Box>
	)
}
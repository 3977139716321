import Github from "@mui/icons-material/GitHub";
import LinkedIn from "@mui/icons-material/LinkedIn";
import Twitter from "@mui/icons-material/Twitter";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { Theme } from '@mui/system/createTheme'
import { SxProps } from '@mui/system/styleFunctionSx'
import { graphql, useStaticQuery } from "gatsby";
import React from 'react';
import { GetSocialsQuery } from '../graphqlTypes';

interface SocialsProps {
	size: 'inherit' | 'large' | 'medium' | 'small',
	sx?: SxProps<Theme>
}

export const Socials: React.FC<SocialsProps> = ({ size, sx }) => {
	sx = sx || {}

	const data: GetSocialsQuery = useStaticQuery(graphql`
    	query GetSocials {
    	  site {
    	    siteMetadata {
    	      social {
    	        twitter
    	        github
    	        email
    	        linkedin
    	      }
    	    }
    	  }
    	}
  	`)

	if (!data.site) {
		throw new Error("couldn't fetch site data! Shouldn't happen!")
	}

	const links = data.site.siteMetadata.social

	return (
		<Box className="thin-scrollbar" sx={{ display: "flex", gap: "1rem", maxWidth: "100%", width: "fit-content", ...sx }}>
			<Tooltip title="Twitter">
				<a
					href={`${links.twitter}`}
					target="_blank"
					style={{ textDecoration: "none", color: "inherit" }}>
					<Twitter fontSize={size} />
				</a>
			</Tooltip>

			<a
				href={`${links.github}`}
				target="_blank"
				style={{ textDecoration: "none", color: "inherit" }}>
				<Tooltip title="Github">
					<Github fontSize={size} />
				</Tooltip>
			</a>

			<a
				href={`${links.linkedin}`}
				target="_blank"
				style={{ textDecoration: "none", color: "inherit" }}>
				<Tooltip title="Linkedin">
					<LinkedIn fontSize={size} />
				</Tooltip>
			</a>

			{/* <a
				href={`mailto:${links.email}`}
				target="_blank"
				style={{ textDecoration: "none", color: "inherit" }}>
				<Tooltip title="Email">
					<MailRounded fontSize={size} />
				</Tooltip>
			</a> */}
		</Box>
	)
}
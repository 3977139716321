import styled from '@emotion/styled';
import Highlight, { defaultProps, Language, Prism } from "prism-react-renderer";
import dracula from 'prism-react-renderer/themes/dracula';
import React from "react";

((typeof global !== "undefined" ? global : window) as any).Prism = Prism;

require("prismjs/components/prism-kotlin");

const Pre = styled.pre`
  width: 100%;
  text-align: left;
  margin: 1em 0;
  padding: 0.5em;
`;

const Line = styled.div<{ highlighted?: boolean }>`
  background-color: ${props => props.highlighted ? "rgba(255, 255, 255, 0.1)" : "none"};
  display: table-row;
`;

const LineNo = styled.span`
  display: table-cell;
  text-align: right;
  padding-right: 1.2em;
  user-select: none;
  opacity: 0.5;
`;

const LineContent = styled.div`
  display: table-cell;
  width: 100%;
`;

interface CodeBlockProps {
	language?: Language | undefined,
	highlighted: Set<number>,
}

export const CodeBlock: React.FC<CodeBlockProps> = (props) => {
	const language = props.language || "javascript" as Language
	const code = `${props.children}`.trim()
	return (
		<Highlight {...defaultProps} theme={dracula} code={code} language={language}>
			{({ className, style, tokens, getLineProps, getTokenProps }) => (
				<Pre className={`${className} thin-scrollbar`} style={{ ...style, borderRadius: '0.95rem' }}>
					{tokens.map((line, i) => {
						const lineNum = i + 1;
						return (
							<Line key={i} {...getLineProps({ line, key: i })} highlighted={props.highlighted.has(lineNum)}>
								<LineNo>{i + 1}</LineNo>
								<LineContent>
									{line.map((token, key) => (
										<span key={key} {...getTokenProps({ token, key })} />
									))}
								</LineContent>
							</Line>
						)
					})}
				</Pre>
			)}
		</Highlight>
	)
}

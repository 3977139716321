import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Helmet, HelmetProps } from "react-helmet"
import { GetSiteMetadataForSeoQuery } from "../graphqlTypes"

type Meta = HelmetProps["meta"]

interface SeoProps {
  title: string
  description?: string
  lang?: string
  meta?: Meta
  image?: {
    src?: string | undefined | null,
    width?: number | undefined | null,
    height?: number | undefined | null,
  } | undefined | null
  pathname?: string | undefined | null
}

const Seo: React.FC<SeoProps> = ({ description, lang, meta, title, image, pathname }) => {
  lang = lang || "en"
  meta = meta || []

  const { site } = useStaticQuery(
    graphql`
      query GetSiteMetadataForSeo {
        site {
          siteMetadata {
            title
            description
            social {
              twitter
            }
            author {
              name
            }
            siteUrl
          }
        }
      }
    `
  ) as GetSiteMetadataForSeoQuery

  if (!site) {
    throw new Error("Couldn't retrieve site node! Shouldn't happen!")
  }

  const siteTitle = site.siteMetadata.title
  const metaDescription = description || site.siteMetadata.description

  const canonical = pathname && `${site.siteMetadata.siteUrl}${pathname}`

  let metaProps: Meta = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:site`,
      content: `${site.siteMetadata.siteUrl}`,
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata.social.twitter || ``,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
  ]

  if (image?.src) {
    metaProps = [
      ...metaProps,
      { property: "og:image", content: `${site.siteMetadata.siteUrl}${image.src}` },
      { name: "twitter:card", content: "summary_large_image", }
    ]
  } else {
    metaProps = [...metaProps, { name: "twitter:card", content: "summary", }]
  }

  if (image?.width) {
    metaProps = [...metaProps, { property: "og:image:width", content: `${image.width}`, }]
  }

  if (image?.height) {
    metaProps = [...metaProps, { property: "og:image:height", content: `${image.height}`, }]
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={title !== siteTitle ? `%s | ${siteTitle}` : `%s`}
      link={canonical ? [{ rel: "canonical", href: canonical }] : []}
      meta={metaProps.concat(meta || [])}
    />
  )
}

export default Seo

import { MDXProvider } from '@mdx-js/react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Language } from 'prism-react-renderer';
// @ts-ignore
import React, { PropsWithChildren } from "react";
import { CodeBlock } from '../CodeBlock';

const range = (start: number, end: number) => {
	return [...Array(end - start + 1).keys()].map(i => i + start);
}

const components = {
	p: (props: any) => <Typography {...props} component="p" variant="body1" paragraph />,
	h1: (props: any) => <Typography {...props} sx={{ mt: 6, mb: 3 }} fontWeight="bold" component="h1" variant="h1" />,
	h2: (props: any) => <Typography {...props} sx={{ mt: 6, mb: 3 }} fontWeight="bold" component="h2" variant="h3" />,
	h3: (props: any) => <Typography {...props} sx={{ mt: 6, mb: 3 }} fontWeight="bold" component="h3" variant="h4" />,
	h4: (props: any) => <Typography {...props} sx={{ mt: 6, mb: 3 }} fontWeight="bold" component="h4" variant="h5" />,
	h5: (props: any) => <Typography {...props} sx={{ mt: 6, mb: 3 }} fontWeight="bold" component="h5" variant="h6" />,
	h6: (props: any) => <Typography {...props} sx={{ mt: 6, mb: 3 }} fontWeight="bold" component="h6" variant="h6" />,
	blockquote: (props: any) => (
		<Paper sx={{ borderRadius: 3, p: 4 }
		} {...props} />
	),
	ul: (props: any) => <Typography {...props} variant="body1" component="ul" />,
	ol: (props: any) => <Typography {...props} variant="body1" component="ol" />,
	li: (props: any) => <Typography {...props} variant="body1" component="li" my="1rem" />,
	code: (props: { className?: string, children: any, highlight?: string }) => {
		const { className, children, highlight } = props
		const language = className ? className.replace("language-", "") as Language : undefined
		const highlightedLines = highlight?.split(",")?.flatMap((highlightSpec) => {
			if (highlightSpec.includes("-")) {
				const [start, end] = highlightSpec.split("-")
				if (!start || !end) {
					throw new Error(`invalid highlight spec: ${highlightSpec}`)
				}
				return range(Number.parseInt(start), Number.parseInt(end))
			} else {
				return Number.parseInt(highlightSpec);
			}
		})

		return (
			<Typography my="2rem" component="div">
				<CodeBlock language={language} highlighted={new Set(highlightedLines)}>
					{children}
				</CodeBlock>
			</Typography>
		)
	},
	hr: Divider,
	a: (props: any) => <a {...props} style={{ color: "inherit" }} />,
	inlineCode: (props: any) => (
		<Box
			component="code"
			backgroundColor="rgba(255, 255, 255, 0.1)"
			borderRadius="0.5rem"
			color="orange"
			px="0.3rem"
			{...props}
		/>
	),
	// table: (() => {
	//   const Table = (props: any) => (
	//     <TableContainer component={Paper}>
	//       <Table {...props} />
	//     </TableContainer>
	//   );
	//   return memo(Table);
	// })(),
	// tr: (() => {
	//   const Tr = (props: any) => <TableRow {...props} />;
	//   return memo(Tr);
	// })(),
	// td: (() => {
	//   const Td = ({ align, ...props }: any) => (
	//     <TableCell align={align || undefined} {...props} />
	//   );
	//   return memo(Td);
	// })(),
	// tbody: (() => {
	//   const TBody = (props: any) => <TableBody {...props} />;
	//   return memo(TBody);
	// })(),
	// th: (() => {
	//   const Th = ({ align, ...props }: any) => (
	//     <TableCell align={align || undefined
	//     } {...props} />
	//   );
	//   return memo(Th);
	// })(),
	// thead: (() => {
	//   const THead = (props: any) => <TableHead {...props} />;
	//   return memo(THead);
	// })(),
	// input: (() => {
	//   const Input = (props: any) => {
	//     const { type } = props;
	//     if (type === 'checkbox') {
	//       return <Checkbox {...props} disabled={false} readOnly={true} />;
	//     }
	//     return <input {...props} />;
	//   };
	//   return memo(Input);
	// })(),
	// wrapper: (() => {
	//   const Wrapper = (props: any) => <div {...props} className="markdown-body" />;
	//   return memo(Wrapper);
	// })(),
};

export const CustomMdxProvider = ({ children }: PropsWithChildren<{}>) => (
	<MDXProvider components={components}>{children}</MDXProvider>
)

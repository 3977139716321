import MoreIcon from '@mui/icons-material/MoreVert';
import RssFeed from '@mui/icons-material/RssFeed';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

type MenuEntries = { [key: string]: string | { link: string, component: (isMobile: boolean) => React.ReactElement } }

const menuEntries: MenuEntries = {
  Blog: "/blog",
  Courses: "/courses",
  Newsletter: "/newsletter",
  About: "/about",
  RSS: {
    link: "/rss.xml",
    component: (isMobile) => isMobile
      ? <Box lineHeight={0} display="flex" alignItems="center" gap="0.2rem">RSS <RssFeed /></Box>
      : (
        <Typography
          variant="h6"
          noWrap
          component="div"
          aria-label={"RSS"}
          lineHeight={0}
        >
          <RssFeed />
        </Typography>
      )
  },
}

export const NavBar = () => {
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const mobileMenuId = 'nav-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {
        Object.entries(menuEntries).map(([label, menuEntry]) => (
          typeof menuEntry === "string"
            ? (
              <MenuItem key={menuEntry}>
                <Link to={menuEntry} style={{ textDecoration: "none", color: "inherit" }}>{label}</Link>
              </MenuItem>
            )
            : (
              <MenuItem key={menuEntry.link}>
                <Link to={menuEntry.link} style={{ textDecoration: "none", color: "inherit" }}>{menuEntry.component(true)}</Link>
              </MenuItem>
            )
        ))
      }
    </Menu>
  );

  return (
    <AppBar position="static" sx={{ mb: "4rem", px: "0.5rem", py: "0.1rem", boxShadow: "none" }}>
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {/* Left menu */}
        <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
          <Box display="flex" gap="1rem" alignItems="center">
            <StaticImage
              layout="fixed"
              formats={["auto", "webp", "avif"]}
              src="../images/the-dev-tavern-logo-white.png"
              width={36}
              height={36}
              quality={95}
              imgStyle={{ borderRadius: "50%" }}
              alt="Profile picture"
            />
            <Typography
              variant="h5"
              noWrap
              component="div"
              fontWeight="bold"
              display={{ xs: "none", md: "block" }}
            >
              The Dev Tavern
            </Typography>
          </Box>
        </Link>

        {/* Right menu */}
        <Box className="menu-right">
          {/* Desktop menu */}
          <Box
            display={{ xs: 'none', sm: 'none', md: 'flex' }}
            gap="2rem"
            alignItems="center"
          >
            {Object.entries(menuEntries).map(([label, menuEntry], index) => (
              <Link key={index} to={typeof menuEntry === "string" ? menuEntry : menuEntry.link} style={{ textDecoration: "none", color: "inherit" }}>
                {typeof menuEntry === "string"
                  ? (
                    <Typography
                      variant="h6"
                      noWrap
                      component="div"
                      aria-label={label}
                    >
                      {label}
                    </Typography>
                  )
                  : menuEntry.component(false)
                }

              </Link>
            ))}
          </Box>

          {/* Mobile menu button */}
          <Box sx={{
            display: { xs: 'flex', sm: 'flex', md: 'none' },
            flexGrow: 1,
            justifyContent: "right",
          }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
              sx={{px: 0}}
            >
              <MoreIcon />
            </IconButton>
          </Box>

        </Box>
      </Toolbar>
      {renderMobileMenu}
    </AppBar>
  );
}
